import {React, useState, useEffect} from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import {Link} from 'react-router-dom';
import '../../../css/reservations.css';

function AddProductForm() { 

  	const [contactInfo, setContactInfo] = useState({
    	firstname: "",
    	lastname: "",
    	email: "",
    	phone: ""

  	});

  	const [selectedFile, setSelectedFile] = useState(null);
  	
  	//const [response, setResponse] = useState(""); 
  	const [textCount, setTextCount] = useState(3000);
  	const limit = 3000;
  	const [address, setAddress] = useState();
  	const [type, setType] = useState(localStorage.getItem("user_type"));

  	//const [userCreds, setUserCreds] = useState();

  	const [contactList, setContactList] = useState([]);

  	const [selectedOption, setSelectedOption] = useState('option1')
  	

  	const [productInfo, setproductInfo] = useState({
  		name:"",
  		price:"",
  		priceCategory:"",
  		price2: "",
  		priceCategory2: "",
  		link:"",
  		otherInformation:"",
  		contactId: "",
  	})

  	

  	const getContacts = async (type) => {

  		let formData2 = new FormData();
  		formData2.append("type", type);
  		formData2.append("creds", localStorage.getItem("user"));

  		const url = "create_user_contacts.php"
		const { data } = await axiosConfig.post(url, formData2);
  		setContactList(data);
  		//console.log(data);

  	};



  	useEffect(() => {
		if(localStorage.getItem("user") !== "is_admin") {
    	if(Object.keys(contactList).length === 0) {
    		getContacts(type);
    	}
    	}
    	
    	
  	});

  	const sendAddProduct = async (e) => {
  		if(window.confirm("Olet lisäämässä tuotteen järjestelmään. Haluatko jatkaa?")) {
  		e.preventDefault();
  		

  		const url = "add_product.php";
  		let formData = new FormData();
  		
  		/*
  		if(typeof type === "undefined") {
  			let type = localStorage.getItem("user_type");
		}
		*/

		formData.append('type', type);
  		formData.append('image', selectedFile);
		Object.keys(productInfo).forEach(key => {
			formData.append(key, productInfo[key]);
		});
		if(typeof address !== "undefined") {
			formData.append("address", address);
		}

  		if(selectedOption === "option1") {
  			console.log(productInfo.contactId);
  			formData.append("contact_id", productInfo.contactId);
  		}
  		else {

  		Object.keys(contactInfo).forEach(key => {
  			//console.log(contactInfo[key]);
  			formData.append(key, contactInfo[key]);
  		} 
  		);
  		}

  		
  		

		//console.log(formData);
		const { data } = await axiosConfig.post(url, formData, { headers: {
      	'Content-Type': 'multipart/form-data'
    	}});
		
		}
		
		window.location.replace("/contentmanagement");
		
  	}


  	


  	

  	const addressField = () => {
  		if(localStorage.getItem("user_type") === "2" || localStorage.getItem("user") === "is_admin") {
  			return (
  				<div>
  				<label>Osoite:</label><br />
  				<input type="text" name="address" value={address} onChange={(e) => setAddress(e.target.value)}></input>
  				</div>
  			)

  		}
  	}

  	const setTheType = (e) => {
  		setType(e.target.value);
  		console.log(e.target.value);
  		if(e.target.value !== "") {
  			getContacts(e.target.value);
  			//console.log(contactList);
  		}
  	}

  	const setThisType = () => {
  		if(localStorage.getItem("user") === "is_admin") {
  			return (
  			<div>
  			<label>Seura:</label><br />	
  			<select id="type" 
  						name="type"
  						onChange={setTheType} required>
  						<option value="">Valitse:</option>
  						<option value="0">Pohjois-Tammelan maa- ja kotitalousseura ry</option>
  						<option value="1">Pohjois-Tammelan maa- ja kotitalousnaiset ry</option>	
						<option value="2">Teuron-Kuuslammin kylätoimintayhdistys ry</option>
  			</select>
  			</div>
  			)
  		}
  	}

  	

  	const handleChange = (event) => {
    	setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  	};

  	const handleProductChange = (event) => {
    	setproductInfo({ ...productInfo, [event.target.name]: event.target.value });
  	};

  	const handleTextAreaChange = (event) => {
  		
  		setTextCount(limit - event.target.value.length);
  		setproductInfo({ ...productInfo, [event.target.name]: event.target.value });
  		
  	}

  	const handleImageChange = (event) => {
  		setSelectedFile(event.target.files[0]);
  		//console.log(event.target.files[0]);
  	}
  	
  	const createContactList = () => {
  		if(typeof contactList !== "undefined" && contactList.length > 0) {
  			return (
  				contactList.map(contact => 
  			<option value={contact.id} key={contact.id} id={contact.id}>{contact.etunimi} {contact.sukunimi}, {contact.puhelin}, {contact.email}</option>
  			)
  			);
  		}
  	}
  	
	
	function buildReservationForm(props) {


		let contactContent;
  		if(selectedOption ==="option1") {
  			contactContent = (
  				<div><select onChange={handleProductChange} value={productInfo.contactId} name="contactId" required>
  				<option value="">Valitse:</option>
  				{createContactList()}</select></div>
  			);
  		}
  		else {
  			contactContent = (
  				<div>
  				<label>Etunimi:</label><br />
				<input className="management-input" type="text" name="firstname"  onChange={handleChange} required></input>
				<br />
				<label>Sukunimi:</label><br />
				<input className="management-input" type="text" name="lastname"  onChange={handleChange} required></input>
				<br />
				<label>Email:</label><br />
				<input className="management-input" type="text" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" onChange={handleChange} required></input>
				<br />
				<label>Puhelin:</label><br />
				<input className="management-input" type="text" name="phone"  onChange={handleChange} required></input>

  				</div>
  			);
  		}


		return (
			<div className="reservation-fonts">
			<br /> 
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link>

			<h1>Lisää kohde:</h1>
			<div className="reservation-form">
				<form onSubmit={sendAddProduct}>
				<br />
				
				<br />
				<br />
				{setThisType()}
				<label>Tuotenimi:</label><br />
				<input className="management-input" type="text" name="name"  value={productInfo.name} onChange={handleProductChange} required></input>
				<br />
				<label>Hinta:</label><br />
				<input className="management-input" type="text" name="price" value={productInfo.price} onChange={handleProductChange} required></input>
				<br />
				<label>Hintaperuste:</label><br />
				<input className="management-input" type="text" name="priceCategory" value={productInfo.priceCategory} onChange={handleProductChange} required></input>
				<br />
				<label>Hinta2:</label><br />
				<input className="management-input" type="text" name="price2" value={productInfo.price2} onChange={handleProductChange}></input>
				<br />
				<label>Hintaperuste2:</label><br />
				<input className="management-input" type="text" name="priceCategory2" value={productInfo.priceCategory2} onChange={handleProductChange}></input>
				<br />
				<label>Kuvatiedosto:</label><br />
				<input className="management-input" type="file" onChange={handleImageChange} required />
				<br />
				<label>Linkki käyttöohjeeseen:</label><br />
				<input className="management-input" type="text" name="link" value={productInfo.link} onChange={handleProductChange}></input>
				<br />
				{addressField()}


				<label>Muut tiedot:</label><br />
				<textarea maxLength="3000" className="management-input-textarea" type="text" name="otherInformation" value={productInfo.otherInformation} onChange={handleTextAreaChange} ></textarea>
				<p>{textCount} merkkiä jäljellä</p>

				<br />
				
				<br />
				<br />
				<br />

				<div className="radio">
      			<label>
        			<input type="radio" 
        				   value="option1"
        				   id="option1"
        				   checked={selectedOption === 'option1'} 
        				   onChange={(e) => setSelectedOption(e.target.value)}/>
       				Käytä olemassaolevia yhteystietoja:

      			</label>
    		</div>
    		<br />
    		<br />
    		<div className="radio">
      			<label>
        			<input type="radio" 
        				   value="option2"
        				   id="option2" 
        				   checked={selectedOption === 'option2'}
        				   onChange={(e) => setSelectedOption(e.target.value)}/>
        			Syötä uudet yhteystiedot:
      			</label>
    		</div>
    		<br />
    		Yhteystiedot:
    		{contactContent}
    		<br />
    		<br />
				<input type="submit" value="Lisää tuote" className="return-link"></input>

				</form>
			</div>
			</div>
		);
		}
		
	
		return (
		<div className="yhdistys-container">
			<div className="yhdistys-wrapper">
      			{buildReservationForm()}
			</div>
		</div>		
		);
	
}
		
export default AddProductForm;