import React from 'react';
import axiosConfig from '../backend/axios/axiosConfig';

import './../css/login.css';


class LoginPage extends React.Component {
	
	
	constructor(props) {
		super(props);
		this.state = {
			pass: '',
			username: '',
			authorized:'',
		}
	};
		
		

		
	handleFormSubmit( event ) {
        event.preventDefault();
		let authData = new FormData();
        authData.append('username', this.state.username)
        authData.append('password', this.state.pass)
        
        axiosConfig({
            method: 'post',
            url: 'checklogin.php',
            data: authData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
        })
        .then(response => response.data).then((data) => {
        if(data[0] === "not_authorized") {
			this.setState({authorized:'Käyttäjätunnus tai salasana on väärä'});
			//console.log(this.state.authorized);
		}
		else {
			localStorage.setItem('user', data[0]);
			localStorage.setItem('user_contact_id', data[1]);
			localStorage.setItem('user_type', data[2]);
			localStorage.setItem('username', data[3]);
			//console.log(localStorage.getItem('user'));
			//console.log(localStorage.getItem('user_type'));
			this.setState({authorized:'Kirjautunut'})
		}
      	window.location.replace("/");
	 
	 }).catch(function (response) {
            //handle error
            //console.log(response)
			
	});
    }
	
	
	render() {
		
		return (
		<div className="loginpage-wrapper">
		
		<br />
		<br />
        <form onSubmit={e => this.handleFormSubmit(e)} className="loginform">
            <label className="label">Käyttäjätunnus</label>
			<br />
            <input type="text" name="username" value={this.state.username} className="inputfield"
                onChange={e => this.setState({ username: e.target.value })}/>
			<br />
            <label>Salasana</label>
			<br />
            <input type="text" name="password" value={this.state.pass} className="inputfield"
                onChange={e => this.setState({ pass: e.target.value })}/>

            
			<br />
			<br />
            <input className="button" type="submit" value="Kirjaudu"/>
        </form>
		
		</div>
		);
	}
	}



export default LoginPage;