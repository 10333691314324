import {React, useState, useEffect} from 'react';
import axiosConfig from '../../../backend/axios/axiosConfig';
import {Link} from 'react-router-dom';
import '../../../css/reservations.css';

function UpdateYhdistysForm() { 

  

  	const [response, setResponse] = useState(""); 
  	const [type, setType] = useState(localStorage.getItem("user_type"));
  	
  	const [yhdistysData, setYhdistysData] = useState([]);
  	const [dataUpdated, setDataUpdated] = useState(false);
  	const [userInfo, setUserInfo] = useState({});
  	const getData = async (type) => {
  		
  		let formData2 = new FormData();
  		formData2.append("type", type);

  		const url = "yhdistys_data.php"
		const { data } = await axiosConfig.post(url, formData2);
  		setYhdistysData(data);
  		setDataUpdated(false);
  		//console.log(yhdistysData);

  		

  	};

  	useEffect(() => {
		if(localStorage.getItem("user") !== "is_admin") {
    		if(Object.keys(yhdistysData).length === 0) {
    			getData(type);
    		}

    	}
    	if(Object.keys(yhdistysData).length > 0 && dataUpdated === false) {
    		yhdistysData.forEach(entry => { 

    			setUserInfo(entry);
    			setDataUpdated(true);
    		}
    		)
    	}
    	//console.log(userInfo);
    	
  	});
  
  	
  	

  	


  	const sendUpdateYhdistys = async (e) => {
  		if(window.confirm("Olet päivittämässä yhdistyksen tietoja. Haluatko jatkaa?")) {
  		e.preventDefault();
  		

  		const url = "update_yhdistys_data.php";
  		let formData = new FormData();
  		
		Object.keys(userInfo).forEach(key => {
  			console.log(userInfo[key]);
  			formData.append(key, userInfo[key]);
  		} 
  		);
		//console.log(formData);
		const { data } = await axiosConfig.post(url, formData, {});
		
		if(data === true) {
			setResponse("Olet päivittänyt yhdistyksen tiedot");
		}
		//console.log(data);
		if(data === false) {
			setResponse("Jotain meni pieleen. Yritä uudelleen.");
		}
		  	
	}
  	}




  

  	const handleUserChange = (event) => {
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  	};


  	const setTheType = (e) => {
  		setType(e.target.value);
  		//console.log(e.target.value);
  		if(e.target.value !== "") {
  		getData(e.target.value);
  		//console.log(yhdistysData);
  		}
  	}

  	const setThisType = () => {
  		if(localStorage.getItem("user") === "is_admin") {
  			return (
  			<div>
  			<label>Seura:</label><br />	
  			<select id="type" 
  						name="type" 
  						onChange={setTheType} required>
  						<option value="">Valitse:</option>
  						<option value="0">Maa- ja kotitalousseura</option>
  						<option value="1">Kotitalousnaiset</option>	
						<option value="2">Kyläyhdistys</option>
  			</select>
  			</div>
  			)
  		}
  	}

  	

  	
	
	function buildReservationForm() {

		
		


		return (
			
			
			
			<div className="reservation-fonts">
			
			<div className="reservation-form">
				<h1>Päivitä yhdistyksen tietoja:</h1>
				<form onSubmit={sendUpdateYhdistys}>
				<br />
				{response}
				<br />
				<br />
				<label>Yhdistyksen nimi:</label><br />
				<input className="management-input" type="text" name="nimi" defaultValue={userInfo.nimi || ''} required readOnly></input>
				<br />
				<label>Y-tunnus:</label><br />
				<input className="management-input" type="text" name="ytunnus" defaultValue={userInfo.ytunnus || ''} required readOnly></input>
				<br />
				<label>Tilinumero:</label><br />
				<input className="management-input" type="text" name="tilinumero" value={userInfo.tilinumero || ''} onChange={handleUserChange} required></input>
				<br />
				<label>Email:</label><br />
				<input className="management-input" type="text" name="email" value={userInfo.email || ''} onChange={handleUserChange}></input>
				<br />
				<label>Puhelin:</label><br />
				<input className="management-input" type="text" name="puhelin" value={userInfo.puhelin || ''} onChange={handleUserChange}></input>
				<br /> 
				
				
			
				

				
				<br />
				<br />
				<br />

				
    		
				<input type="submit" className="return-link" value="Päivitä tiedot"></input>

				</form>
			</div>
			</div>
		);
		
		}
	
		return (
		<div className="yhdistys-container">
		<div  className="yhdistys-wrapper">
			<div>
			<Link to="/contentmanagement" className="return-link">Takaisin hallintapaneeliin</Link><br /><br />
			
			</div>
			<div>
			{setThisType()}
			</div>
			<div>
      			{buildReservationForm()}
			</div>
			</div>
		</div>		
		);
	
}
		
export default UpdateYhdistysForm;