import {Routes, Route} from 'react-router-dom';
import React from 'react';
import '../../css/loader.css';

import EventCalendar from '../calendar';
import Contacts from '../contacts';
import ToimintaSuunnitelma from '../theplan';
import Machines from '../machines';
import Machine from '../machine';
import News from '../news';
import MainContent from '../maincontent';
import Gallery from '../gallery';
import Yhdistys from '../description';
import JoinMember from '../joinmember';
import Services from '../services';
import Service from '../service';
import LoginPage from '../loginpage';
import ContentManagement from '../contentmanagement/contentmanagement';
import MobileLinks from '../mobilelinks';
import ToimintaKertomukset from '../toimintakertomukset';
import ReservationCalendar from '../reservation_calendar';
import ReservationForm from '../reservation_form';
import Tarvikkeet from '../vuokrattavat_tarvikkeet';
import Tarvike from '../tarvike';
import NonvalidatedReservations from '../contentmanagement/reservation_management/check_nonvalidated_reservations';
import ShowReservations from '../contentmanagement/reservation_management/show_reservations';
import Reservation from '../contentmanagement/reservation_management/reservation';
import ListInvoices from '../contentmanagement/reservation_management/list_invoices';
import Invoice from '../contentmanagement/reservation_management/invoice';
import MuutTuotteet from '../muut_tuotteet';
import MuuTuote from '../muu_tuote';
import AddUserForm from '../contentmanagement/reservation_management/add_user';
import ModifyReservation from '../contentmanagement/reservation_management/modify_reservation';
import DeleteUsers from '../contentmanagement/reservation_management/delete_users';
import DeleteProducts from '../contentmanagement/reservation_management/delete_product';
import AddProductForm from '../contentmanagement/reservation_management/add_product';
import ReservationDone from '../contentmanagement/reservation_management/reservation_done';
import UpdateYhdistysForm from '../contentmanagement/reservation_management/update_yhdistys_data';
import ProductListing from '../contentmanagement/reservation_management/product_listing';
import UpdateProductForm from '../contentmanagement/reservation_management/update_product_data';
import ListContacts from '../contentmanagement/reservation_management/contact_listing';
import UpdateContactForm from '../contentmanagement/reservation_management/update_contact_data';
import ListNews from '../contentmanagement/list_news';
import UpdateNewsForm from '../contentmanagement/update_news';
import Project from '../projectfunding';

function MaamiesRoutes() {
 
  



  

  return (
  	<div>
  	<Routes>
	  <Route path="/" element={<MainContent />} />
	  <Route path="/calendar" element={<EventCalendar />} />
	  <Route path="/contacts" element={<Contacts />} />
	  <Route path="/theplan" element={<ToimintaSuunnitelma />} />
	  <Route path="/machines" element={<Machines />} />
	  <Route path="/machines/:id" element={<Machine machine=":id" />} />
	  <Route path="/news" element={<News />} />
	  <Route path="/galleria" element={<Gallery />} />
	  <Route path="/description" element={<Yhdistys />} />
	  <Route path="/liity" element={<JoinMember />} />
	  <Route path="/services" element={<Services />} />
	  <Route path="/services/:id" element={<Service service=":id" />} />
	  <Route path="/loginpage" element={<LoginPage />} />
	  <Route path="/contentmanagement" element={<ContentManagement />} />
	  <Route path="/mobilelinks" element={<MobileLinks />} />
	  <Route path="/toimintakertomukset" element={<ToimintaKertomukset />} />
	  <Route path="/reservation_calendar/:id" element={<ReservationCalendar product=":id" />} />
		<Route path="/reservation_form" element={<ReservationForm />} />
		<Route path="/vuokrattavat_tarvikkeet" element={<Tarvikkeet />} />
		<Route path="/vuokrattavat_tarvikkeet/:id" element={<Tarvike tarvike=":id" />} />
		
		<Route path="/check_nonvalidated_reservations" element={<NonvalidatedReservations />} />
		<Route path="/show_reservations" element={<ShowReservations/>} />
		<Route path="/show_reservations/:res_ref" element={<Reservation reservation=":res_ref"/>} />
		<Route path="/list_invoices" element={<ListInvoices />} />
		<Route path="/list_invoices/:res_ref" element={<Invoice />} />
		<Route path="/kylayhdistys_kohteet" element={<MuutTuotteet />} />
		<Route path="/kylayhdistys_kohteet/:id" element={<MuuTuote />} />
		<Route path="/add_user" element={<AddUserForm />} />
		<Route path="/modify_reservation/:res_ref" element={<ModifyReservation />} />
		<Route path="/delete_users" element={<DeleteUsers />} />
		<Route path="/delete_product" element={<DeleteProducts />} />
		<Route path="/add_product" element={<AddProductForm />} />
		<Route path="/reservation_done" element={<ReservationDone/>} />
		<Route path="/update_yhdistys_data" element={<UpdateYhdistysForm />} />
		<Route path="/update_product_data" element={<UpdateProductForm />} />
		<Route path="/product_listing" element={<ProductListing />} />
		<Route path="/contact_listing" element={<ListContacts />} />
		<Route path="/update_contact_data" element={<UpdateContactForm/>} />
		<Route path="/list_news" element ={<ListNews />} />
		<Route path="/update_news" element ={<UpdateNewsForm />} />

		<Route path="/projectfunding" element={<Project />} />
		</Routes>
	</div>

	
  )
  
}

export default MaamiesRoutes;